@import '@fortawesome/fontawesome-free/css/all.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'IRANSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-error {
  border: 2px solid red !important;
}

.card-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.bg-image-container {
  /* width: 110px !important;
  height: 110px !important; */
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  overflow: hidden;
}