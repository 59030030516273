.no-caret::after {
    display: none !important;
}

.chat_avatar{
 height: 50px;
 width: 50px;
 border-radius: '70%';
}

.chat-support-icon {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #e74c3c;
    color: white;
    padding: 15px;
    width: 75px;
    height: 75px;;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    font-size: 4vh;
    z-index: 1000;

    display: flex;              /* Use flexbox */
    justify-content: center;    /* Center horizontally */
    align-items: center;        /* Center vertically */
  }
  
  .chat-support-icon:hover {
    background-color: #aa3325;
  }

